'use client'

import React, { useEffect, useState } from 'react'
import posthog from 'posthog-js'
import { PostHogProvider as PHProvider } from 'posthog-js/react'
import { ENV } from "scripts/environment";

export function PostHogProvider({ children }: { children: React.ReactNode }) {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

    useEffect(() => {
      posthog.init(ENV.POSTHOG.POSTHOG_KEY, {
        api_host: ENV.POSTHOG.POSTHOG_HOST,
        capture_pageview: false,
        loaded: () => {
          setIsInitialized(true)
        }
      })
  }, [])
  if(!isInitialized) return <></>

  return (
    <PHProvider client={posthog}>
      {children}
    </PHProvider>
  )
}