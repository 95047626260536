import { ClickAwayListener } from "@mui/material";
import GameLevelIcons from "Components/GameLevelIcons";
import MeasurementUnit from "Components/MeasurementUnit";
import VerticalTabs from "Components/base/TabsLink/VerticalTabs";
import useGameUserInfoHook from "Hooks/GameUserInfoHook/GameUserInfoHook.hook";
import useGameUserLevelHook from "Hooks/GameUserLevelHook/GameUserLevelHook.hook";
import { useCheckAppHook } from "Hooks/useCheckApp.hook";
import useWindowSize from "Hooks/useWindowSize";
import UploadAvatar from "Screens/Profile/UploadAvatar";
import classNames from "classnames";
import { CONSTANTS } from "consts";
import { UserAppContext } from "contexts";
import React, { useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import CoinIcon from "./coin.png";
import style from "./profileCard.module.scss";

const ProfileCard = () => {
    const { gameUserInfo } = useGameUserInfoHook();
    const { gameUserLevel } = useGameUserLevelHook()

    const { appId } = useCheckAppHook();

    const windowSize = useWindowSize();
    const getFontsizeCoin = (coin: number) => {
        let baseFontSize = 0;
        if (windowSize?.width <= 1024) {
            baseFontSize = 18
        } else {
            baseFontSize = 28;
        }
        const coinStr = String(coin);
        if (coinStr.length > 7) {
            return baseFontSize * (7 / Number(coinStr.length))
        } else {
            return baseFontSize
        }
    }
    // const { data: userData, loading } = useSharedQuery();
    const { userProfile } = useContext(UserAppContext);
    const userProfileData = userProfile?.data

    const getDisplayName = () => {
        return userProfileData?.username?.toLocaleUpperCase() || ""
    };

    const dataTab = [
        {
            label: "OVERVIEW",
            link: `${CONSTANTS.NAVIGATION_URL.WORKOUT_HISTORY}`,
        },
        {
            label: "WORKOUTS",
            link: `${CONSTANTS.NAVIGATION_URL.WORKOUT_HISTORY_DETAIL}`,
        },
        {
            label: "COMPARISON",
            link: `${CONSTANTS.NAVIGATION_URL.WORKOUT_HISTORY_COMPARE_PERIOD}`,
        },
        {
            label: "ACHIEVEMENTS",
            link: `${CONSTANTS.NAVIGATION_URL.WORKOUT_HISTORY_ACHIEVEMENTS}`,
        },
    ];
    const history = useHistory();
    const handleClickAvatar = () => {
        history.push(CONSTANTS.NAVIGATION_URL.PROFILE);
    };

    const isApkBike = useMemo(() => {
        return appId === CONSTANTS.APP.AVIRON_BIKE
    }, [appId])

    const handleClickUsername = () => {
        const usernameEl = document.getElementById('userNameWrapper');
        let scrollWidth = usernameEl?.scrollWidth || 0;
        let scrollLeft = usernameEl?.scrollLeft || 0;
        if (usernameEl) {
            if (scrollLeft === 0) {
                usernameEl?.scrollTo({
                    left: scrollWidth,
                    behavior: "smooth"
                })
            } else {
                usernameEl?.scrollTo({
                    left: 0,
                    behavior: "smooth"
                })
            }
        }
    }

    const handleClickOutsideUsername = () => {
        const usernameEl = document.getElementById('userNameWrapper');
        let scrollWidth = usernameEl?.scrollWidth || 0;
        let scrollLeft = usernameEl?.scrollLeft || 0;
        if (usernameEl) {
            if (scrollLeft > 0) {
                usernameEl?.scrollTo({
                    left: 0,
                    behavior: "smooth"
                })
            }
        }
    }

    return (
        <div className={style["ProfileCardWrapper"] + " ProfileCardWrapper"}>
            <div className={style['userInfoWrapper']}>
                <div className={style["avatarWrapper"]} onClick={handleClickAvatar}>
                    <UploadAvatar userData={userProfileData} disableUpload={true} />
                </div>
                <div className={style['block']}>
                    {windowSize?.width <= 1250 && (
                        <ClickAwayListener onClickAway={handleClickOutsideUsername}>
                            <div
                                className={classNames(style["userNameWrapper"])}
                                onClick={() => handleClickUsername()}
                                id="userNameWrapper"
                            >
                                {getDisplayName()}
                            </div>
                        </ClickAwayListener>
                    )}
                    <div className={style["levelGroupWrapper"]}>
                        <div className={style["name"]}>{gameUserInfo?.level}</div>
                        <div className={style["medal"]}>
                            <GameLevelIcons
                                iconSrc={gameUserLevel?.metadata?.current?.iconUrl?.replace(
                                    ".png",
                                    "_256x256.png"
                                )}
                                id={gameUserLevel?.metadata?.current?.id || 0}
                            />
                        </div>
                    </div>
                    {(appId === CONSTANTS.APP.AVIRON_BIKE || appId === CONSTANTS.APP.AVIRON_TREADMILL) && (
                        <div className={style["unitGroupWrapper"]}>
                            <div className={style["medal"]}>
                                {windowSize?.width > 1250 && <MeasurementUnit />}
                            </div>
                        </div>
                    )}
                    <div className={style["coinWrapper"]}>
                        <div className={style["name"]}>EXPERIENCE COINS</div>
                        <div className={style["point"]}>
                            <img src={CoinIcon} alt="coin icon" />
                            <div
                                className={style["pointText"]}
                                style={{
                                    fontSize: getFontsizeCoin(
                                        gameUserInfo?.expCoinsWallet || 0
                                    ),
                                }}
                            >
                                {gameUserInfo?.expCoinsWallet || ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style["menuTabWrapper"]}>
                <VerticalTabs
                    orientation={
                        windowSize?.width <= 1250 ? "horizontal" : "vertical"
                    }
                    dataTab={dataTab}
                    tabName={"profileTab"}
                />
            </div>
        </div>
    );
};

export default ProfileCard;
