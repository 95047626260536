import { CONSTANTS } from "consts";

export const changeProfileValidate = (values: any, initialValues: any) => {
  let errors: any = {};
  // const usernameReg = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\s~]/; // check special characters
  // const nameReg = /[`!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?~]/;
  const nameReg =/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/; // Just allow english text, number and space
  const notAllowSpace=/^\S*$/; // not allow space

  if (!values.username) {
    errors.username = '*Username must contain 3 - 13 characters - letter and number only.';
  } else if (values.username.length < 3) {
    errors.username = '*Username must contain 3 - 13 characters - letter and number only.';
  } else if (values.username.length > 13) {
    errors.username = '*Username must contain 3 - 13 characters - letter and number only.';
  } else if (!notAllowSpace.test(values.username) || !nameReg.test(values.username)) {
    errors.username = '*Username must contain 3 - 13 characters - letter and number only.';
  }

  const nameErrorMsg = ' should not be blank, must contain at least 1 letter or number, must not include accented characters, and has a maximum length of 64.'
  if (!nameReg.test(values.firstName)) {
    errors.firstName = '*First Name'+ nameErrorMsg;
  } else if (values.firstName.trim().length === 0){
    errors.firstName = '*First Name' + nameErrorMsg;
  } else if (values.firstName.length > 64) {
    errors.firstName = '*First Name' + nameErrorMsg;
  } else if (values.firstName.trim().length > 0 && values.lastName.trim().length === 0) {
    errors.lastName = '*Last Name' + nameErrorMsg;
  } else if (values.firstName.length === 0) {
    errors.firstName = "*First Name should not be blank, should have at least 1 letter or number and max length is 64."
  }

  if (!nameReg.test(values.lastName)) {
    errors.lastName = 'Last Name' + nameErrorMsg;
  } else if (values.lastName.trim().length === 0){
    errors.lastName = '*Last Name' + nameErrorMsg;
  } else if (values.lastName.length > 64) {
    errors.lastName = '*Last Name' + nameErrorMsg;
  } else if (values.lastName.trim().length > 0 && values.firstName.trim().length === 0) {
    errors.firstName = '*First Name' + nameErrorMsg;
  } else if (values.lastName.length === 0) {
    errors.lastName = "*Last Name should not be blank, should have at least 1 letter or number and max length is 64."
  }

  if(values.height > 0 || Number(initialValues.height) > 0) {
    if (values.height_unit === CONSTANTS.PROFILE_PAGE.HEIGHT_UNITS.CM) {
      if (values.height < 122) {
        errors.height = "Please enter a height between 122 and 274 cm."
      } else if (values.height > 274) {
        errors.height = "Please enter a height between 122 and 274 cm."
      }
    } else {
      if (values.height < 48) {
        errors.height = "Please enter a height between 4 and 9 ft."
      } else if (values.height > 108) {
        errors.height = "Please enter a height between 4 and 9 ft."
      }
    }
  }

  if(values.weight > 0 || Number(initialValues.weight) > 0) {
    if (values.weight_unit === CONSTANTS.PROFILE_PAGE.WEIGHT_UNITS.LBS) {
      if (values.weight < 20) {
        errors.weight = "Please enter a weight between 20 and 750 lbs."
      } else if (values.weight > 750) {
        errors.weight = "Please enter a weight between 20 and 750 lbs."
      }
    } else {
      if (values.weight < 9) {
        errors.weight = "Please enter a weight between 9 and 340 kg."
      } else if (values.weight > 340) {
        errors.weight = "Please enter a weight between 9 and 340 kg."
      }
    }
  }
if(values.year === "") {
  errors.year = '*Year should not be blank.'
}
if(values.month === "") {
  errors.month = '*Month should not be blank.'
}
if(values.day === "") {
  errors.day = '*Day should not be blank.'
}
  return errors;
}

