import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DataTable from "Components/DataTable";
import { AvironLoading } from "Components/Icons/AvironLoading";
import PageTitle from "Components/PageTitle";
import { CONSTANTS } from "consts";
import get from "lodash/get";
import moment from "moment";
import { default as React, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { ENV } from "scripts/environment";
import { mobileDetect } from "scripts/mobileDetect";
// import MembershipCard from './MembershipCard';
import { Stack } from "@mui/material";
import loadable from "@react-loadable/revised";
import { Helmet } from "react-helmet";
import {
    useAvironSubscriptionsQuery,
    useCheckUserReceiveMembershipOfferQuery,
    useGetAvironSubscriptionQuery,
    useGetLatestSubscriptionCanceledByUserQuery,
    useSharedQuery,
} from "services/webServer";
import { modelColors } from "../membership.constant";
import {
    RenderEmptyDataMembershipWithC2,
    useQueryMembershipHistoryListC2
} from "./MembershipsMain.actions";
import {
    membershipsColumnsForAPK,
    getStatus,
    MEMBERSHIP_OFFER_KEY,
} from "./MembershipsMain.constant";
import "./style.scss";
import { getUrlVars } from "utils/url";
import ReactiveMembershipNoticeModal from "./ReactiveMembershipNotice";
import ReactiveMembershipNoticeWithoutPromoModal from "./ReactiveMembershipNoticeWithoutPromo";
import AppChip from "Components/AppChip";
import { formatAppIds } from "utils/membership";
import { UserAppContext } from "contexts";
import MembershipOfferModal from "./MembershipOfferModal";
import { IMembershipOfferModalRef } from "./MembershipOfferModal/MembershipOfferModal";
import MembershipOfferInvalidModal from "./MembershipOfferInvalidModal";
import { IMembershipOfferInvalidModalRef } from "./MembershipOfferInvalidModal/MembershipOfferInvalidModal";
import MembershipCard from "./MembershipCard";
import MembershipOfferTwoMonthsModal from "./MembershipOfferTwoMonthsModal ";
import { IMembershipOfferTwoMonthsModalRef } from "./MembershipOfferTwoMonthsModal /MembershipOfferTwoMonthsModal";
import { handleCheckIsFreeMembership } from "../utils/handleCheckIsFreeMembership";

const initModalState = {
    openReactiveNotice: false,
    openReactiveNoticeWithoutPromo: false,
};

const stateColors = [
    { id: "inactive", value: "Not Activated", color: "#212EA9" },
    { id: "active", value: "Activated", color: "#16C231" },
    { id: "past_due", value: "Past Due", color: "#B8270C" },
    { id: "canceled", value: "Canceled", color: "#949494" },
    { id: "ended", value: "Ended", color: "#6C0074" },
    { id: "pause_collection", value: "Pause Payment", color: "#F58C0F" },
];

const OFFER_STATUS = ["Activated", "Paused", "Pause Payment"]
const END_MEMBERSHIP_STATUS = ["Canceled", "Ended"]
const FIT_PLAN_MEMBERSHIP = ["membership-financing", "membership-warranty-financing"]

export default function MembershipMain() {
    const queryString = getUrlVars();

    const [modalState, setModalState] = React.useState(initModalState);
    const [loading, setLoading] = useState(false);
    const { auth } = useContext(UserAppContext);
    const userId = useMemo(() => auth?.id, [auth]);
    const membershipOfferAnnualRef = useRef<IMembershipOfferModalRef>(null);
    const membershipOfferMonthlyRef = useRef<IMembershipOfferTwoMonthsModalRef>(null);
    const membershipOfferInvalidRef = useRef<IMembershipOfferInvalidModalRef>(null);
    const [isAllowOffer, setIsAllowOffer] = useState<boolean | undefined>(undefined);
    const [isShowBtnOffer, setIsShowBtnOffer] = useState<boolean>(false);
    const [isOneMonthOffer, setIsOneMonthOffer] = useState<boolean>(false);

    const { data: checkUserReceivedMembershipOffer } =
        useCheckUserReceiveMembershipOfferQuery({
            variables: {
                userId: userId,
            },
            skip: Boolean(!userId),
        });

    const { data: latestMembershipCanceled } = useGetLatestSubscriptionCanceledByUserQuery({
        variables: {
            userId,
        },
        skip: Boolean(!userId),
        fetchPolicy: "no-cache"
    })

    const resetModalState = () => {
        setModalState(initModalState);
    };


    const isReceivedMembershipOffer = useMemo(() => {
        if (typeof checkUserReceivedMembershipOffer === "undefined")
            return undefined;

        return Boolean(
            checkUserReceivedMembershipOffer?.checkUserReceiveMembershipOffer
        );
    }, [checkUserReceivedMembershipOffer]);



    // useEffect(() => {
    //     const queryString = getUrlVars();
    //     const reactiveSource = sessionStorage.getItem("reactiveSource");
    //     if(reactiveSource && queryString.source && queryString.source !== reactiveSource){
    //         sessionStorage.removeItem("reactive")
    //     }
    //     if (queryString.source && queryString.source === "klaviyo-email" && queryString.action == "upgrade") {
    //         sessionStorage.setItem("upgradePlan", "true");
    //     }
    // }, [])

    const handleCloseNoticeReactiveModal = () => {
        sessionStorage.setItem("isShowNoticeReactive", "true");
        resetModalState()
    }

    const handleCloseNoticeReactiveWithoutPromoModal = () => {
        sessionStorage.setItem("isShowNoticeReactiveWithoutPromo", "true");
        resetModalState()
    }

    const [activeTab, setActiveTab] = useState(0);
    // Show data for c2
    const appId = localStorage.getItem("appId");
    const isC2App = React.useMemo(() => {
        if (typeof appId === "string") {
            return (
                [
                    CONSTANTS.APP.C2_ROWER,
                    CONSTANTS.APP.C2_SKIERG,
                    CONSTANTS.APP.C2_BIKEERG,
                    CONSTANTS.APP.WATER_ROWER,
                ].indexOf(Number(appId)) !== -1
            );
        }
        return false;
    }, [appId]);

    const isAPK = !isC2App;
    const columns = membershipsColumnsForAPK;

    const history = useHistory();
    const isMobile = mobileDetect();

    const [membershipsList, setMembershipsList] = React.useState<
        undefined | any[]
    >(undefined);

    const { handleQueryData } = useQueryMembershipHistoryListC2();

    const handClickDetail = (id: string, appType: "apple" | "apk") => {
        if(appType === "apple"){
            history.push(
                `${CONSTANTS.NAVIGATION_URL.MEMBERSHIP_APPLE}/${id}`
            );
        } else {
            history.push(`${CONSTANTS.NAVIGATION_URL.MEMBERSHIPS}/${id}/${window.location.search}`);
        }
    };

    const getModelCell = (appIds: number[]) => {
        return (<div className="model-chip-wrapper">
            {appIds.map(appId => {
                return <AppChip appId={appId} />
            })}
        </div>)
    }

  

    const handleSelectApp = (appId: number) => {
        const domTarget = document.getElementById(
            `select-app-${appId}`
        );
        if (domTarget && window.innerWidth < 900) {
            domTarget.scrollIntoView({
                block: "center",
                inline: "center",
                behavior: "smooth",
            });
        }
        setActiveTab(appId);
    };

    const safeSetVisible = async ({
        ref,
        retries = 5,
        delay = 900,
    }: {
        ref: React.RefObject<{ setIsVisible: (visible: boolean) => void }>;
        retries?: number;
        delay?: number;
    }) => {
        for (let i = 0; i < retries; i++) {
            try {
                // Check if ref is ready
                if (ref?.current) {
                    // Do action open modal when ref is ready
                    ref?.current?.setIsVisible(true);
                    return;
                }
            } catch (error) {
                console.warn(`Retry ${i + 1}: Action failed`, error);
            }

            await new Promise((resolve) => setTimeout(resolve, delay));
        }
    };

    const currentListMembership = useMemo(() => {
        if (typeof membershipsList === "undefined") {
            return membershipsList
        }
        return membershipsList.filter((membership => {
            if (activeTab === 0) {
                return true;
            }
            return membership?.appIds?.includes(activeTab)
        }))
    }, [membershipsList, activeTab])

    const isEnoughTime = useMemo(() => {
        if(typeof latestMembershipCanceled === "undefined") return undefined;
        const cancelTime = latestMembershipCanceled?.getLatestSubscriptionCanceledByUser?.cancel_day as string;
        if(Boolean(!cancelTime)) return false;
        const checkIsEnoughTime = moment().isSameOrAfter(moment(cancelTime).add(10, "seconds"))

        return checkIsEnoughTime;
    }, [latestMembershipCanceled]);

    console.log("latestMembershipCanceled", latestMembershipCanceled)

    const isLatestMonthly = useMemo(() => {
        if (typeof latestMembershipCanceled === "undefined") return false;
        const isMonthly = latestMembershipCanceled?.getLatestSubscriptionCanceledByUser?.name?.includes("Monthly") || Number(latestMembershipCanceled?.getLatestSubscriptionCanceledByUser?.game_plan_id) === Number(ENV.MEMBERSHIP.FREE_1_MONTH_GAME_PLAN_ID)
        return isMonthly;
    }, [latestMembershipCanceled]);

    useEffect(() => {
        setLoading(true)
            handleQueryData()
                .then(async (res) => {
                    const result = res?.data?.memberships || [];

                const rows = result.map((item: any, index: number) => {
                    const status = getStatus(
                        item?.status?.toString(),
                        item?.isPauseCollection
                    );
                    const isCanceled = status?.name.includes("Canceled");

                    return {
                        id: item.orgId,
                        appType: item.paymentMethod,
                        status: (
                            <p
                                style={{
                                    color: status?.color,
                                    cursor: "pointer",
                                }}
                                onClick={() => { handClickDetail(item.orgId, item?.paymentMethod) }}
                            >
                                {status?.name}
                            </p>
                        ),
                        name: item?.planName || '---',
                        model: getModelCell(formatAppIds(item?.appIds)),
                        startAt: item?.purchaseAt
                                    ? moment
                                        .unix(item.purchaseAt)
                                        .format("MM/DD/YYYY")
                                    : "---",
                            nextBillingDate: isCanceled
                                ? "---"
                                : item?.expireAt
                                    ? moment
                                        .unix(item.expireAt)
                                        .format("MM/DD/YYYY")
                                    : "---",
                            token: item?.orgKey || '---',
                            appIds: formatAppIds(item?.appIds),
                            statusName: status?.name,
                            expireAt: item?.expireAt,
                            gamePlanId: item?.planId,
                        };
                    });
                    setMembershipsList(rows);
                    setLoading(false);
                })
                .catch(() => {
                    setMembershipsList([]);
                    setLoading(false);
                });

    }, []);

    useEffect(() => {
        if (
            typeof isReceivedMembershipOffer === "undefined" ||
            typeof isAllowOffer === "undefined" || 
            typeof latestMembershipCanceled === "undefined"
        ) {
            return;
        }
        const isFitPlanMembership =  FIT_PLAN_MEMBERSHIP.includes(latestMembershipCanceled?.getLatestSubscriptionCanceledByUser?.productId || "")
        if(isFitPlanMembership) {
            return;
        }
        const queryString = getUrlVars();
        const reactiveSource = sessionStorage.getItem("reactiveSource");
        if(reactiveSource && queryString.source && queryString.source !== reactiveSource){
            sessionStorage.removeItem("reactive")
        }
        if (queryString.source && (queryString.source === "qr-code" || queryString.source === "klaviyo-email")) {
            if(isAllowOffer && Boolean(!isReceivedMembershipOffer)) {
                setIsShowBtnOffer(true)
                setIsOneMonthOffer(true)
                sessionStorage.removeItem("reactiveSource");
                sessionStorage.removeItem("reactiveWithoutPromo");
                sessionStorage.removeItem("isShowNoticeReactive");
                sessionStorage.setItem("reactive", "true");
                localStorage.setItem(MEMBERSHIP_OFFER_KEY, JSON.stringify(userId));
                safeSetVisible({
                    ref: membershipOfferMonthlyRef
                });
            } else {
                safeSetVisible({
                    ref: membershipOfferInvalidRef,
                });
            }
        } else if (queryString.source && queryString.source === "qr-code-WTHPR24") {
            if(Boolean(isReceivedMembershipOffer) || !isAllowOffer ) {
                safeSetVisible({
                    ref: membershipOfferInvalidRef,
                });
                return;
            }
            setIsShowBtnOffer(false)
            localStorage.removeItem(MEMBERSHIP_OFFER_KEY);
            sessionStorage.removeItem("reactive");
            sessionStorage.setItem("reactiveWithoutPromo", "true");
            sessionStorage.setItem("reactiveSource", queryString.source);
            const isShowNoticeReactive = sessionStorage.getItem("isShowNoticeReactiveWithoutPromo");
            if (!isShowNoticeReactive) {
                setModalState({ ...initModalState, openReactiveNoticeWithoutPromo: true })
            }
        }
    }, [isAllowOffer, isReceivedMembershipOffer, latestMembershipCanceled])

    useEffect(() => {
        if (
            typeof isReceivedMembershipOffer === "undefined" ||
            typeof isEnoughTime === "undefined" ||
            typeof isAllowOffer === "undefined" || 
            typeof latestMembershipCanceled === "undefined"
        ) {
            return;
        }
        const isFitPlanMembership =  FIT_PLAN_MEMBERSHIP.includes(latestMembershipCanceled?.getLatestSubscriptionCanceledByUser?.productId || "")
        if(isFitPlanMembership) {
            return;
        }

        if (queryString?.utm_source_key === MEMBERSHIP_OFFER_KEY) {
            if (
                Boolean(!isReceivedMembershipOffer) &&
                isEnoughTime && isAllowOffer
            ) {
                setIsOneMonthOffer(false)
                setIsShowBtnOffer(true);
                sessionStorage.removeItem("reactive");
                sessionStorage.removeItem("reactiveSource");
                sessionStorage.removeItem("reactiveWithoutPromo");
                sessionStorage.removeItem("isShowNoticeReactive");
                localStorage.setItem(MEMBERSHIP_OFFER_KEY, JSON.stringify(userId));
                safeSetVisible({
                    ref: isLatestMonthly ? membershipOfferMonthlyRef : membershipOfferAnnualRef
                });
            } else {
                safeSetVisible({
                    ref: membershipOfferInvalidRef,
                });
            }
        }
    }, [userId, isReceivedMembershipOffer, isEnoughTime, isLatestMonthly, isAllowOffer, latestMembershipCanceled]);

    useEffect(() => {
        if (typeof currentListMembership === "undefined") return;
        const isAllow =
            currentListMembership?.length > 0 &&
            !currentListMembership?.some((item) =>
                OFFER_STATUS.includes(item?.statusName)
            ) &&
            currentListMembership.some(
                (item) =>
                    END_MEMBERSHIP_STATUS.includes(item?.statusName) &&
                    !item?.name?.includes("Commercial") &&
                    !handleCheckIsFreeMembership(item.gamePlanId)
            );
        setIsAllowOffer(isAllow);
    }, [currentListMembership]);

    useEffect(() => {
        const isTheSameUser =
            localStorage.getItem(MEMBERSHIP_OFFER_KEY) ===
            JSON.stringify(sessionStorage.getItem("uid"));
        setIsShowBtnOffer(isTheSameUser);
        setIsOneMonthOffer(Boolean(sessionStorage.getItem("reactive")));
    }, []);

    if (loading || typeof membershipsList === "undefined" || typeof currentListMembership === "undefined" || typeof isReceivedMembershipOffer === "undefined") {
        return (
            <div className="height-loading">
                <AvironLoading />
            </div>
        ) 
       
    }
    if (!loading && membershipsList?.length === 0 && !isAPK) {
        return RenderEmptyDataMembershipWithC2();
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Memberships | Aviron</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <ReactiveMembershipNoticeModal
                open={modalState.openReactiveNotice}
                onClose={handleCloseNoticeReactiveModal}
            />
            <ReactiveMembershipNoticeWithoutPromoModal
                open={modalState.openReactiveNoticeWithoutPromo}
                onClose={handleCloseNoticeReactiveWithoutPromoModal}
            />
            <MembershipOfferModal
                ref={membershipOfferAnnualRef}
                membershipId={latestMembershipCanceled?.getLatestSubscriptionCanceledByUser?.id}
            />
            <MembershipOfferTwoMonthsModal
                ref={membershipOfferMonthlyRef}
                membershipId={latestMembershipCanceled?.getLatestSubscriptionCanceledByUser?.id}
            />
            <MembershipOfferInvalidModal ref={membershipOfferInvalidRef} />

            <Box
                sx={{
                    marginBottom: "16px",
                }}
            >
                <PageTitle>Memberships</PageTitle>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: !isC2App ? "space-between" : "flex-end",
                    flexWrap: "wrap",
                    alignItems: "center",
                }}
            >
                {!isC2App && (
                    <Box
                        sx={{
                            width: {
                                xs: "100%",
                                md: "auto",
                            },
                            marginBottom: {
                                xs: "32px",
                                sm: "24px",
                            },
                        }}
                    >
                        <Stack
                            direction="row"
                            spacing={{
                                xs: "8px",
                                md: "8px",
                            }}
                            overflow="auto"
                            display="flex"
                            sx={{
                                flexWrap: {
                                    xs: "nowrap",
                                    md: "wrap",
                                },
                                justifyContent: {
                                    xs: "space-between",
                                    md: "start",
                                },
                                paddingBottom: {
                                    xs: "4px",
                                    md: 0,
                                },

                                "::-webkit-scrollbar": {
                                    display: "none",
                                },
                            }}
                            className={"select-app-tab"}
                        >
                            <div>
                                <Button
                                    size="large"
                                    variant="contained"
                                    onClick={handleSelectApp.bind(null, 0)}
                                    className={
                                        activeTab === 0 ? "tab--active" : ""
                                    }
                                    id={`select-app-0`}
                                >
                                    ALL
                                </Button>
                            </div>
                            <div>
                                <Button
                                    size="large"
                                    variant="contained"
                                    onClick={handleSelectApp.bind(null, 1)}
                                    className={
                                        activeTab === 1 ? "tab--active" : ""
                                    }
                                    id={`select-app-1`}
                                >
                                    WITH AVIRON TOUCHSCREEN
                                </Button>
                            </div>
                            <div>
                                <Button
                                    size="large"
                                    variant="contained"
                                    onClick={handleSelectApp.bind(null, 16)}
                                    className={
                                        activeTab === 16 ? "tab--active" : ""
                                    }
                                    id={`select-app-16`}
                                >
                                    WITH PERSONAL TABLET
                                </Button>
                            </div>
                        </Stack>
                    </Box>
                )}
                {Boolean(!isReceivedMembershipOffer) &&
                    Boolean(isShowBtnOffer) &&
                    Boolean(isAllowOffer) &&
                    (Boolean(isEnoughTime) || isOneMonthOffer) &&
                   (
                        <Box
                            sx={{
                                position: "relative",
                                margin: {
                                    xs: "auto",
                                    sm: "0",
                                },
                                marginBottom: {
                                    xs: "32px",
                                    sm: "24px",
                                },
                                width: {
                                    xs: "96%",
                                    sm: "auto",
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    color: "#fff",
                                    fontWeight: 600,
                                    fontSize: "12px",
                                    backgroundColor: "#161616",
                                    border: " 2px solid #F4F4F4",
                                    display: "flex",
                                    alignItems: "center",
                                    borderRadius: "30px",
                                    padding: "4px 6px",
                                    position: "absolute",
                                    top: "-25px",
                                    transform: {
                                        xs: "translateX(72%)",
                                        sm: "translateX(15px)",
                                    },
                                    zIndex: 1,
                                }}
                            >
                                <img
                                    alt="Aviron Membership Offer"
                                    src="/images/icons/icon-clock.png"
                                    width={13}
                                    height={13}
                                    style={{
                                        marginRight: "4px",
                                        objectFit: "cover",
                                    }}
                                />
                                LIMITED TIME OFFER
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    safeSetVisible({
                                        ref:
                                            isLatestMonthly || isOneMonthOffer
                                                ? membershipOfferMonthlyRef
                                                : membershipOfferAnnualRef,
                                    });
                                }}
                                color="secondary"
                                sx={{
                                    minWidth:
                                        isLatestMonthly || isOneMonthOffer
                                            ? "187px !important"
                                            : "auto",
                                }}
                            >
                                {isOneMonthOffer
                                    ? "1 MONTH FREE"
                                    : isLatestMonthly
                                    ? "2 MONTHS FREE"
                                    : "50% OFF FOR 1 YEAR"}
                            </Button>
                        </Box>
                    )}
            </Box>
            {!loading && currentListMembership.length === 0 && (
                <Box
                    sx={{
                        width: "fit-content",
                        margin: "64px auto",
                        textAlign: "center",
                    }}
                >
                    <img src="/images/icons/document.svg" />
                    <Typography mt="24px" mb="16px" variant="h4" component="h4">
                        You don't have any memberships yet.
                    </Typography>
                    {activeTab !== CONSTANTS.APP.AVIRON_GO && (
                        <>
                            <Typography mb="16px" variant="body1" component="p">
                                You can go to shop page to purchase a
                                membership.
                            </Typography>
                            <a
                                href={`${ENV.STOREFRONT_DOMAIN}/shop/home-fitness/personal-membership/`}
                            >
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    sx={{ width: "167px" }}
                                >
                                    SHOP NOW
                                </Button>
                            </a>
                        </>
                    )}
                </Box>
            )}
            {!isMobile ? (
                <Box>
                    {currentListMembership.length > 0 && (
                        <Box>
                            <DataTable
                                collums={columns}
                                rows={currentListMembership}
                                colors={stateColors}
                                colorField="state"
                            />
                        </Box>
                    )}
                </Box>
            ) : (
                <Box>
                    <Box>
                        {/* <Typography component="h4" variant="h5">AVIRON MEMBERSHIP</Typography> */}
                        {currentListMembership?.map((row: any) => {
                            return (
                                <MembershipCard
                                    {...row}
                                    stateColors={stateColors}
                                    onClickDetail={handClickDetail}
                                />
                            );
                        })}
                    </Box>
                </Box>
            )}
        </>
    );
}
