import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { ExpandMore } from "@mui/icons-material";
import style from './SelectFormField.module.scss';
import classNames from 'classnames';

const SelectField: React.FunctionComponent<{
    name?: string
    value?: any,
    error?: string | undefined,
    fontSize?: number,
    label?: string,
    size?: "small" | "medium" | undefined,
    variant?: "standard" | "outlined" | "filled" | undefined,
    fullWidth?: boolean
    id?: string
    onChange?: any
    options: {
        name: any,
        value: any,
    }[],
    placeholder?: string
    sx?: any
    inputProps?: any
    className?: any
}> = ({ value, error, fontSize, label, name, variant, fullWidth, onChange, options, size, placeholder, sx, inputProps, className }) => {

    return (
        <div className={style["SelectFormFieldWrapper"] + ' SelectFormFieldWrapper'}>
            <Grid container alignItems="center">
                <Grid item xs={12} sm={12} md={12} mb="8px">
                    {label && (
                        <Box flexShrink={0} mr="30px">
                            <Typography component="label" variant="h6">
                                {label}
                            </Typography>
                        </Box>
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <FormControl fullWidth>
                        {placeholder && <div className={classNames(style['placeholderCustom'], 'placeholderCustom')}>{placeholder}</div>}
                        <Select
                            labelId="sidebar-select-label"
                            id="sidebar-select"
                            value={value}
                            label=""
                            onChange={onChange}
                            defaultValue={value}
                            variant={variant}
                            name={name}
                            size={size}
                            fullWidth={fullWidth}
                            inputProps={inputProps ? inputProps : { style: { fontSize: "16px" } }}
                            MenuProps={{className: 'MenuSelectFormField'}}
                            IconComponent={ExpandMore}
                            sx={sx}
                            className={className}
                        >
                            {options.map((option) => {
                                return (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    {error && (
                        <Box color="#E60A32" fontSize={12} mt={'8px'}>
                            {error}
                        </Box>
                    )}
                </Grid>
            </Grid>
        </div>
    );
} 

export default SelectField;

