import { Box, Button, Stack, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "Components/FormElements/TextField";
import NumberAndUnit from "Components/FormElements/NumberAndUnit";
import RadioSelect from "Components/FormElements/RadioSelect";
import SelectField from "Components/FormElements/Select";
import { CONSTANTS } from "consts";
import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { changeProfileValidate } from "./changeProfileValidate";
import Pencil2 from 'Components/Icons/Pencil2'
import "./style.scss";
import NumberAndHeightUnit from "Components/FormElements/NumberAndHeightUnit";

const GENDER_DATA = [
    { value: 2, label: "Male" },
    { value: 1, label: "Female" },
    { value: 0, label: "Other" },
];

const HEIGHT_UNITS = [
    { value: 0, label: "Inch" },
    { value: 1, label: "Cm" },
    // { value: , label: "meters" },
];

const WEIGHT_UNITS = [
    { value: 0, label: "Lbs" },
    { value: 1, label: "Kg" },
];

const initMonthOptions: any[] = [
    {
        name: "Jan",
        value: "01",
    },
    {
        name: "Feb",
        value: "02",
    },
    {
        name: "Mar",
        value: "03",
    },
    {
        name: "Apr",
        value: "04",
    },
    {
        name: "May",
        value: "05",
    },
    {
        name: "Jun",
        value: "06",
    },
    {
        name: "Jul",
        value: "07",
    },
    {
        name: "Aug",
        value: "08",
    },
    {
        name: "Sep",
        value: "09",
    },
    {
        name: "Oct",
        value: "10",
    },
    {
        name: "Nov",
        value: "11",
    },
    {
        name: "Dec",
        value: "12",
    },
];

const initDayOptions: any[] = new Array(31).fill(0).map((item, index) => {
    let day = index < 9 ? "0" + (index + 1) : index + 1;
    return { name: day, value: day };
});

const yearOption: any[] = new Array(moment().year() - 1904)
    .fill(0)
    .map((item, index) => {
        return { name: 1904 + index + 1, value: 1904 + index + 1 };
    });

const KG_TO_LBS_RATE = 2.20462;
const INCH_TO_CM_RATE = 2.54;

const ChangeProfileForm = (props: any) => {
    const {
        gameProfile,
        onSubmitting,
        isPasswordLess,
        onClickSetPassword,
        onClickRemovePassword,
        onClickChangePassword,
        onClickChangeEmail,
    } = props;

    // const [dialCode, setDialCode] = React.useState("+1");
    const [dayOptions, setDayOptions] = React.useState<any>(initDayOptions);
    const [monthOptions, setMonthOptions] =
        React.useState<any>(initMonthOptions);

    const email = get(gameProfile, "email");

    const getBirthDay = (birthday: string) => {
        if(!birthday || birthday === '1900-01-01') {
            return {
                year: '',
                month: '',
                day: ''
            }
        } else {
            const birthdaySplit = birthday.split("-");
            if(birthdaySplit.length === 3) {
                return {
                    year: birthdaySplit[0],
                    month: birthdaySplit[1],
                    day: birthdaySplit[2]
                }
            }
            return {
                year: '',
                month: '',
                day: ''
            }
        }
    }

    const initialValues = {
        username: get(gameProfile, "username", "") || "",
        firstName: get(gameProfile, "firstName") || "",
        lastName: get(gameProfile, "lastName") || "",
        emails: email,
        // country: get(userData, "currentUser.profile.country") || "US",
        // phone: get(userData, "currentUser.profile.phone"),
        gender: get(gameProfile, "gender") || 0,
        weight: get(gameProfile, "weight", 0),
        weight_unit: get(gameProfile, "weightUnit", 0),
        height: get(gameProfile, "height", 0),
        height_unit: get(gameProfile, "heightUnit", 0),
        day: getBirthDay(gameProfile?.birthDay).day,
        month: getBirthDay(gameProfile?.birthDay).month,
        year: getBirthDay(gameProfile?.birthDay).year,
    };

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (values) => {
            onSubmitting(values);
        },
        validate: (values) => changeProfileValidate(values, initialValues),
    });

    // React.useEffect(() => {
    //     formik.setValues(initialValues);
    // }, [gameProfile]);

    // React.useEffect(() => {
    //     handleChangeYear({
    //         target: {
    //             value: Number(initialValues.year),
    //             name: "year",
    //         },
    //     });
    // }, [initialValues.year]);

    const handleChangeMonth = (event: any) => {
        let numDay: number = 28;
        switch (event.target.value) {
            case "01":
            case "03":
            case "05":
            case "07":
            case "08":
            case "10":
            case "12":
                numDay = 31;
                break;
            case "04":
            case "06":
            case "09":
            case "11":
                if (Number(formik.values.day) === 31) {
                    formik.setFieldValue("day", "01");
                }
                numDay = 30;
                break;
            case "02":
                if (
                    Number(formik.values.year) % 400 === 0 ||
                    (Number(formik.values.year) % 4 === 0 &&
                        Number(formik.values.year) % 100 !== 0)
                ) {
                    numDay = 29;
                }
                if (Number(formik.values.day) > numDay) {
                    formik.setFieldValue("day", "01");
                }
                break;
            default:
                break;
        }
        if (
            Number(event.target.value) === moment().month() + 1 &&
            Number(formik.values.year) === moment().year()
        ) {
            numDay = Number(moment().format("D")) - 1;
            if (Number(formik.values.day) > Number(moment().format("D"))) {
                formik.setFieldValue("day", "01");
            }
        }
        formik.handleChange(event);
        setDayOptions(
            new Array(numDay).fill(0).map((item, index) => {
                let day = index < 9 ? "0" + (index + 1) : index + 1;
                return { name: day, value: day };
            })
        );
    };

    const handleChangeYear = (event: any) => {
        if (formik.values.month === "02") {
            let numDay = 28;
            if (
                event.target.value % 400 === 0 ||
                (event.target.value % 4 === 0 && event.target.value % 100 !== 0)
            ) {
                numDay = 29;
            }
            if (Number(formik.values.day) > numDay) {
                formik.setFieldValue("day", "01");
            }
            setDayOptions(
                new Array(numDay).fill(0).map((item, index) => {
                    let day = index < 9 ? "0" + (index + 1) : index + 1;
                    return { name: day, value: day };
                })
            );
        }
        if (event.target.value === moment().year()) {
            setMonthOptions(initMonthOptions.slice(0, moment().month() + 1));
            if (Number(formik.values.month) > moment().month() + 1) {
                formik.setFieldValue("month", "01");
                formik.setFieldValue("day", "01");
            }
        } else {
            setMonthOptions(initMonthOptions);
        }
        formik.handleChange(event);
    };

    const handleChangeWeightUnit = (event: { value: number; name: string }) => {
        let newWeight = formik.values.weight;
        if (formik.values.weight_unit !== event.value) {
            if (event.value === CONSTANTS.PROFILE_PAGE.WEIGHT_UNITS.KGS) {
                newWeight = Math.round(formik.values.weight / KG_TO_LBS_RATE);
            } else {
                newWeight = Math.round(formik.values.weight * KG_TO_LBS_RATE);
            }
        }
        formik.setValues(
            Object.assign(formik.values, {
                weight_unit: event.value,
                weight: newWeight,
            })
        );
        formik.setFieldTouched("weight");
    };

    const handleChangeHeightUnit = (event: { value: number; name: string }) => {
        let newHeight = formik.values.height;
        if (formik.values.height_unit !== event.value) {
            if (event.value === CONSTANTS.PROFILE_PAGE.HEIGHT_UNITS.IN) {
                newHeight = Math.round((formik.values.height / INCH_TO_CM_RATE));
            } else {
                newHeight = Math.round(formik.values.height * INCH_TO_CM_RATE);
            }
        }
        formik.setValues(
            Object.assign(formik.values, {
                height_unit: event.value,
                height: newHeight,
            })
        );
        formik.setFieldTouched("height");
    };

    const handleChangeInch = (inch: number) => {
        setTimeout(()=>{
            formik.setFieldValue('height', inch)
            formik.setFieldValue('height_unit', 0)
        }, 500)
    }

    useEffect(()=>{
        setTimeout(()=>{
            formik.resetForm();
        }, 500)
    }, [])

    // React.useEffect(() => {
    //     handleChangeMonth({
    //         target: {
    //             name: "month",
    //             value: birthdaySplited[1],
    //         },
    //     });
    // }, [birthdaySplited[1]]);

    useEffect(()=>{
        if(gameProfile) {
            const birthday = getBirthDay(gameProfile?.birthDay);
            formik.setFieldValue('year', birthday.year)
            formik.setFieldValue('month', birthday.month)
            formik.setFieldValue('day', birthday.day)
        }
    }, [gameProfile])

    const scrollToForm = () => {
        const element = document.getElementById("profile-form");
        if (element) {
            const rect = element.getBoundingClientRect();
            const elementDistanceFromTop = element.offsetTop - 80;
            if (rect.y < 0) {
                window.scrollTo({
                    left: 0,
                    top: elementDistanceFromTop,
                    behavior: "smooth",
                });
            }
        }
    };

    React.useEffect(() => {
        if (formik.isSubmitting && !isEmpty(formik.errors)) {
            scrollToForm();
        }
    }, [formik.errors, formik.isSubmitting]);

    return (
        <div>
            <form
                onSubmit={formik.handleSubmit}
                className="change-profile-form-component"
                id="profile-form"
            >
                <Grid container spacing={3.5} marginBottom="40px">
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            name="username"
                            label="Username"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText={formik.errors.username}
                            onChange={formik.handleChange}
                            value={formik.values.username}
                            sx={{
                                background: "#FFFFFF",
                                fontSize: "18",
                            }}
                            inputProps={{
                                style: {
                                    height: "50px",
                                    padding: "0 14px",
                                    textTransform: "uppercase",
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <TextField
                            name="firstName"
                            label="First Name"
                            variant="outlined"
                            sx={{
                                background: "#FFFFFF",
                                borderColor: "#FFF",
                                fontSize: "18",
                            }}
                            fullWidth
                            size="small"
                            helperText={formik.errors.firstName}
                            onChange={formik.handleChange}
                            value={formik.values.firstName}
                            inputProps={{
                                style: {
                                    height: "50px",
                                    padding: "0 14px",
                                },
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        pl={{
                            xs: "0",
                            // md: "16px"
                        }}
                    >
                        <TextField
                            name="lastName"
                            label="Last Name"
                            variant="outlined"
                            sx={{
                                background: "#FFFFFF",
                                borderColor: "#FFF",
                                fontSize: "18",
                            }}
                            fullWidth
                            size="small"
                            helperText={formik.errors.lastName}
                            onChange={formik.handleChange}
                            value={formik.values.lastName}
                            inputProps={{
                                style: {
                                    height: "50px",
                                    padding: "0 14px",
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <div className={"emailFormGroup"}>
                            <TextField
                                name="emails"
                                label="Email"
                                type="email"
                                disabled
                                variant="outlined"
                                sx={{
                                    background: "#FFFFFF",
                                    borderColor: "#FFF",
                                    fontSize: "18",
                                }}
                                fullWidth
                                size="small"
                                onChange={formik.handleChange}
                                value={formik.values.emails}
                                inputProps={{
                                    style: {
                                        height: "50px",
                                        padding: "0 14px",
                                    },
                                }}
                            />
                            <div className="updateEmailBtn" onClick={onClickChangeEmail}>
                                <Pencil2 />
                                <div className="text">Update email</div>
                            </div>
                        </div>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        pl={{
                            xs: "0",
                            // md: "16px"
                        }}
                    >
                        <Typography component="label" variant="h6">
                            Birthday
                        </Typography>
                        <Stack
                            direction="row"
                            spacing={{
                                xs: 1,
                                md: "36px",
                            }}
                            justifyContent="space-between"
                            mt="8px"
                        >
                            <SelectField
                                name="year"
                                // label="Year"
                                variant="outlined"
                                fontSize={18}
                                fullWidth
                                size="small"
                                onChange={handleChangeYear}
                                value={formik.values.year}
                                options={yearOption}
                                placeholder={formik.values.year ? '' : 'YYYY'}
                                error={formik?.errors?.year as string}
                            />
                            <SelectField
                                name="month"
                                // label="Month"
                                variant="outlined"
                                fontSize={18}
                                fullWidth
                                size="small"
                                onChange={handleChangeMonth}
                                value={formik.values.month}
                                options={monthOptions}
                                placeholder={formik.values.month ? '' : 'MM'}
                                error={formik?.errors?.month as string}
                            />
                            <SelectField
                                name="day"
                                // label="Day"
                                variant="outlined"
                                fontSize={18}
                                fullWidth
                                size="small"
                                onChange={formik.handleChange}
                                value={formik.values.day}
                                options={dayOptions}
                                placeholder={formik.values.year ? '' : 'DD'}
                                error={formik?.errors?.day as string}
                            />
                        </Stack>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={8}
                        lg={6}
                        pt="23px !important"
                    >
                        <RadioSelect
                            name="gender"
                            label="Gender"
                            row
                            fontSize={16}
                            selectData={GENDER_DATA}
                            onChange={formik.handleChange}
                            value={formik.values.gender}
                        />
                    </Grid>
                    <Grid padding="0" md={4}></Grid>
                    <Grid item xs={12} sm={12} md={12} pt="23px !important">
                        <NumberAndUnit
                            label="Weight"
                            variant="outlined"
                            fontSize={16}
                            txtFieldName="weight"
                            error={
                                formik.errors.weight && formik.touched.weight
                                    ? formik.errors.weight
                                    : null
                            }
                            txtFieldValue={formik.values.weight}
                            txtFieldOnChange={(e: any) => {
                                formik.setFieldTouched("weight");
                                if (formik.values.weight < 0) {
                                    formik.setFieldValue("weight", 0);
                                }
                                formik.handleChange(e);
                            }}
                            txtOnBlur={() => {
                                if (formik.values.weight === "") {
                                    formik.setFieldValue("weight", 0);
                                }
                            }}
                            txtOnFocus={()=>{
                                if (formik.values.weight === 0) {
                                    formik.setFieldValue("weight", "");
                                }
                            }}
                            selectData={WEIGHT_UNITS}
                            selectFieldName="weight_unit"
                            selectFieldOnChange={handleChangeWeightUnit}
                            selectFieldValue={formik.values.weight_unit}
                        />
                    </Grid>
                    <Grid padding="0" md={4}></Grid>
                    <Grid item xs={12} sm={12} md={12} pt="23px !important">
                        {formik.values.height_unit === 0 ? (
                            <NumberAndHeightUnit
                                txtFieldName="height"
                                error={
                                    formik.errors.height &&
                                    formik.touched.height
                                        ? formik.errors.height
                                        : null
                                }
                                height={formik.values.height}
                                handleChangeInch={handleChangeInch}
                                unitData={HEIGHT_UNITS}
                                unitFieldName="height_unit"
                                unitFieldOnChange={handleChangeHeightUnit}
                                unitFieldValue={formik.values.height_unit}
                                formik={formik}
                            />
                        ) : (
                            <NumberAndUnit
                                label="Height"
                                variant="outlined"
                                fontSize={16}
                                txtFieldName="height"
                                error={
                                    formik.errors.height &&
                                    formik.touched.height
                                        ? formik.errors.height
                                        : null
                                }
                                txtFieldValue={formik.values.height}
                                txtFieldOnChange={(e: any) => {
                                    formik.setFieldTouched("height");
                                    if (formik.values.height < 0) {
                                        formik.setFieldValue("height", 0);
                                    }
                                    formik.handleChange(e);
                                }}
                                txtOnBlur={() => {
                                    if (formik.values.height === "") {
                                        formik.setFieldValue("height", 0);
                                    }
                                }}
                                txtOnFocus={()=>{
                                    if (formik.values.height === 0) {
                                        formik.setFieldValue("height", "");
                                    }
                                }}
                                onClickPlus={() => {
                                    formik.setFieldTouched("height");
                                    formik.setFieldValue(
                                        "height",
                                        (
                                            Number(formik.values.height) + 1
                                        ).toFixed(2)
                                    );
                                }}
                                selectData={HEIGHT_UNITS}
                                selectFieldName="height_unit"
                                selectFieldOnChange={handleChangeHeightUnit}
                                selectFieldValue={formik.values.height_unit}
                            />
                        )}
                    </Grid>
                </Grid>
                <Divider
                    sx={{
                        margin: "24px 0",
                    }}
                />
                <Box display="flex" justifyContent="space-bettwen" mt="40px">
                    {isPasswordLess ? (
                        <Grid container justifyContent={"center"}>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    pb: {
                                        xs: "16px",
                                        md: "10",
                                    },
                                }}
                            >
                                <Button
                                    type="button"
                                    variant="outlined"
                                    size="medium"
                                    onClick={onClickSetPassword}
                                    sx={{
                                        width: "100%",
                                        // borderRadius: "12px",
                                        borderColor: "rgba(0, 0, 0, 0.23)",
                                        textTransform: "none",
                                        fontFamily: "Work Sans",
                                    }}
                                >
                                    <div className="icon-with-label">
                                        <img
                                            src="/images/icons/lock.svg"
                                            alt="icon"
                                        />
                                        Set password
                                    </div>
                                </Button>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container justifyContent={"center"}>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{
                                    pr: {
                                        xs: "0",
                                        md: "16px",
                                    },
                                    pb: {
                                        xs: "16px",
                                        md: "10",
                                    },
                                }}
                            >
                                <Button
                                    type="button"
                                    variant="outlined"
                                    size="medium"
                                    onClick={onClickRemovePassword}
                                    sx={{
                                        width: "100%",
                                        // borderRadius: "12px",
                                        borderColor: "rgba(0, 0, 0, 0.23)",
                                        textTransform: "none",
                                        fontFamily: "Work Sans",
                                    }}
                                >
                                    <div className="icon-with-label">
                                        <img
                                            src="/images/icons/lock.svg"
                                            alt="icon"
                                        />
                                        Remove password
                                    </div>
                                </Button>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{
                                    pl: {
                                        sx: "0",
                                        md: "16px",
                                    },
                                    pb: {
                                        xs: "16px",
                                        md: "10",
                                    },
                                }}
                            >
                                <Button
                                    type="button"
                                    variant="outlined"
                                    size="medium"
                                    onClick={onClickChangePassword}
                                    sx={{
                                        width: "100%",
                                        // borderRadius: "12px",
                                        borderColor: "rgba(0, 0, 0, 0.23)",
                                        textTransform: "none",
                                        fontFamily: "Work Sans",
                                    }}
                                >
                                    <div className="icon-with-label">
                                        <img
                                            src="/images/icons/lock.svg"
                                            alt="icon"
                                        />
                                        Change password
                                    </div>
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Box>
                <Divider
                    sx={{
                        margin: "16px 0",
                    }}
                />
                <div className="save-btn-container">
                    <Button
                        type="submit"
                        size="medium"
                        sx={{
                            width: { xs: "100%" },
                            background: "#F5490F",
                            color: "white",
                            "&:hover": {
                                background: "black",
                            },
                        }}
                    >
                        SAVE
                    </Button>
                </div>
                {/* <Divider /> */}
                {/* <br />
            <Typography component="h3" variant="h3">Password</Typography>
            <Typography component="p" variant="subtitle1" mt="8px">You can set a permanent password if you don't want to use temporary login codes.</Typography> */}
            </form>
        </div>
    );
};

export default ChangeProfileForm;
