import { Box, Button, Typography } from "@mui/material";
import GoBack from "Components/GoBack";
import useQueryParams from "Hooks/useQueryParams";
import React, { useEffect, useMemo, useRef } from "react";
import { Helmet } from "react-helmet";
import { useGetSubscriptionData } from "./ReviewAndCancel.hooks";
import { AvironLoading } from "Components/Icons/AvironLoading";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { CONSTANTS } from "consts";
import Swal from "sweetalert2";
import { useCancelUserSubscriptionMutation } from "services/webServer";
import MembershipCanceledModal, {
    IMembershipCanceledModalRef,
} from "Screens/Memberships/Detail/CancelReasons/MembershipCanceledModal";
import { useTrackingData } from "Components/TrackingData";
import { vwo } from "tracking/vwo";
import { handleCheckIsFreeMembership } from "Screens/Memberships/utils/handleCheckIsFreeMembership";
import { get } from "lodash";
import posthog from "posthog-js";

const data = [
    {
        id: 1,
        icon: (
            <img
                src="/images/membership/review-and-cancel-icon-1.png"
                alt="icon"
                width={"20px"}
                height={"20px"}
            />
        ),
        text: "All programs except Metrics Monitor",
    },
    {
        id: 2,
        icon: (
            <img
                src="/images/membership/review-and-cancel-icon-2.png"
                alt="icon"
                width={"20px"}
                height={"20px"}
            />
        ),
        text: "Monthly updated workouts and content",
    },
    {
        id: 3,
        icon: (
            <img
                src="/images/membership/review-and-cancel-icon-3.png"
                alt="icon"
                width={"20px"}
                height={"20px"}
            />
        ),
        text: "Future new games, coaches, and other immersive experiences in the Aviron World",
    },
];

enum SUBSCRIPTION_TYPE_TRACKING {
    MONTHLY = "monthly",
    ANNUAL = "annual",
}

const ReviewAndCancel = () => {
    const history = useHistory();
    const { id = "", entry = "offer_reject_click" } = useQueryParams();
    const { subscriptionData, isLoadingSubscription, error } =
        useGetSubscriptionData(id);
    const [cancelUserSubscription] = useCancelUserSubscriptionMutation({
        onCompleted: () => {
            const subscriptionType =
                subscriptionData?.avironSubscription?.plan?.interval ===
                SUBSCRIPTION_TYPE_TRACKING.MONTHLY
                    ? SUBSCRIPTION_TYPE_TRACKING.MONTHLY
                    : SUBSCRIPTION_TYPE_TRACKING.ANNUAL;
            vwo.trackingMembershipCancellation({
                type: subscriptionType
            });
            posthog.capture("web_membership_cancelled", {
                type: subscriptionType
            })
            sessionStorage.removeItem("cancelReason");
            membershipCancelSuccessRef.current?.setIsVisible(true);
        },
        onError: () => {
            history.replace(CONSTANTS.NAVIGATION_URL.MEMBERSHIPS);
        },
    });
    const { trackingReviewAndCancelView, trackingNotCancelClick } =
        useTrackingData(id);

    const membershipCancelSuccessRef =
        useRef<IMembershipCanceledModalRef>(null);

    const handleRenderErrorContent = () => {
        return (
            <>
                {Swal.fire("Error", "Something went wrong", "error").then(
                    (result) => {
                        if (result.isConfirmed) {
                            history.replace(
                                CONSTANTS.NAVIGATION_URL.MEMBERSHIPS
                            );
                        }
                    }
                )}
                ;
            </>
        );
    };

    const handleChooseCancel = async () => {
        const reasonData = JSON.parse(
            sessionStorage.getItem("cancelReason") as string
        );
        Swal.showLoading();
        await cancelUserSubscription({
            variables: {
                id,
                reason: reasonData?.reason || "Other",
                reasonDetail: reasonData?.reasonDetail || "",
            },
        });

        Swal.close();
    };

    useEffect(() => {
        if (subscriptionData) {
            trackingReviewAndCancelView(subscriptionData, entry);
        }
    }, [subscriptionData]);

    const isDuringFreeOffer:boolean = useMemo(() => {
        const offerUntil = get(subscriptionData, "avironSubscription.remark.offer_until")

        if (!offerUntil) return false;
    
        const offerUntilNumber = Number(offerUntil);
        if (isNaN(offerUntilNumber)) return false;
    
        return moment().isBefore(moment.unix(offerUntilNumber));
    }, [data]);

    const endDate = useMemo(() => {
        switch (subscriptionData?.avironSubscription?.plan?.interval) {
            case "monthly":
                const isFreeMembership = handleCheckIsFreeMembership(subscriptionData?.avironSubscription?.plan?.gamePlanId as number)

                if(isFreeMembership || (get(subscriptionData, "avironSubscription.remark.reactive") && isDuringFreeOffer)) {
                    const date1 = moment().add(30, "days");
                    const date2 = moment(
                        new Date(
                            subscriptionData?.avironSubscription
                                ?.currentPeriodEnd as string
                        )
                    )
                    const isBeforeDate = date1.isBefore(date2);
                    return isBeforeDate
                        ? date1.format("MMMM Do, YYYY")
                        : date2.format("MMMM Do, YYYY");
                }
                return moment(
                    new Date(
                        subscriptionData?.avironSubscription
                            ?.currentPeriodEnd as string
                    )
                ).format("MMMM Do, YYYY");

            case "yearly":
                const date1 = moment().add(30, "days");
                const date2 = moment(
                    new Date(
                        subscriptionData?.avironSubscription
                            ?.currentPeriodEnd as string
                    )
                );
                const isBeforeDate = date1.isBefore(date2);
                return isBeforeDate
                    ? date1.format("MMMM Do, YYYY")
                    : date2.format("MMMM Do, YYYY");

            default:
                return moment(new Date()).format("MMMM Do, YYYY");
        }
    }, [subscriptionData, isDuringFreeOffer]);

    if (isLoadingSubscription) return <AvironLoading />;
    if (Boolean(error)) {
        handleRenderErrorContent();
    }

    return (
        <Box
            sx={{
                marginTop: {
                    xs: "24px",
                    md: "0",
                },
                marginBottom: {
                    xs: "24px",
                    md: "0",
                },
                paddingLeft: {
                    xs: "12px",
                    md: "0",
                },
                paddingRight: {
                    xs: "12px",
                    md: "0",
                },
            }}
        >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Membership Review And Cancel | Aviron</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <GoBack />

            <Box
                sx={{
                    padding: {
                        xs: "16px 12px",
                        sm: "40px",
                    },
                    backgroundColor: "#fff",
                    marginTop: "24px",
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 700,
                        fontSize: {
                            xs: "18px",
                            md: "24px",
                        },
                        lineHeight: {
                            xs: "20px",
                            md: "25.08px",
                        },
                        letterSpacing: "0.01em",
                        marginBottom: "24px",
                        fontFamily: "GRIFTER",
                    }}
                >
                    REVIEW AND CANCEL
                </Typography>
                <Box
                    sx={{
                        backgroundColor: "#f4f4f4",
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "space-between",
                        padding: {
                            xs: "12px",
                            md: "24px",
                        },
                        marginBottom: {
                            xs: "24px",
                            md: "40px",
                        },
                        flexDirection: {
                            xs: "column",
                            md: "row",
                        },
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontFamily: "Work Sans",
                            fontSize: {
                                xs: "16px",
                                md: "20px",
                            },
                            lineHeight: {
                                xs: "20px",
                                md: "24px",
                            },
                            order: {
                                xs: 2,
                                md: 1,
                            },
                            textTransform: "uppercase",
                        }}
                    >
                        {subscriptionData?.avironSubscription?.plan?.name}
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: "Work Sans",
                            fontWeight: 500,
                            fontSize: {
                                xs: "14px",
                                md: "16px",
                            },
                            lineHeight: {
                                xs: "14px",
                                md: "20px",
                            },
                            color: "#FF4106",
                            order: {
                                xs: 1,
                                md: 2,
                            },
                            marginBottom: {
                                xs: "8px",
                                md: 0,
                            },
                        }}
                    >
                        Access until {endDate}
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: "16px",
                            lineHeight: "20px",
                            marginBottom: {
                                xs: "16px",
                                md: "24px",
                            },
                        }}
                    >
                        If you cancel, once your access expires, all profiles on
                        your machine will lose access to:
                    </Typography>
                    {data.map((item) => (
                        <Box
                            sx={{
                                display: "flex",
                                marginBottom: "16px",
                                marginLeft: {
                                    xs: "0",
                                    md: "12px",
                                },
                            }}
                            key={item.id}
                        >
                            <Box
                                sx={{
                                    marginBottom: "1px",
                                }}
                            >
                                {item.icon}
                            </Box>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    marginLeft: "8px",
                                }}
                            >
                                {item.text}
                            </Typography>
                        </Box>
                    ))}
                </Box>

                <Box
                    sx={{
                        display: "inline-flex",
                        backgroundColor: "#F4F4F4",
                        padding: "8px",
                        borderRadius: "8px",
                        marginTop: "8px",
                        marginBottom: {
                            xs: "122px",
                            md: "80px",
                        },
                    }}
                >
                    <img
                        src="/images/membership/review-and-cancel-icon-4.png"
                        alt="icon"
                        width={"18px"}
                        height={"18px"}
                        style={{ marginTop: "2px" }}
                    />
                    <Typography
                        sx={{
                            fontWeight: 400,
                            fontSize: "16px",
                            lineHeight: "20px",
                            marginLeft: "12px",
                        }}
                    >
                        If you have a Lifetime Warranty, it will revert to the
                        Standard Warranty and cannot be reinstated.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "end",
                        flexDirection: {
                            xs: "column",
                            md: "row",
                        },
                    }}
                >
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            trackingNotCancelClick(
                                subscriptionData,
                                "review_cancel_view"
                            );
                            history.push(
                                `${CONSTANTS.NAVIGATION_URL.MEMBERSHIPS}/${id}`
                            );
                        }}
                        sx={{
                            marginBottom: {
                                xs: "16px",
                                md: 0,
                            },
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "19.98px",
                            letterSpacing: "0.01em",
                        }}
                    >
                        DON'T CANCEL
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        className="btn-cancel-subscription"
                        onClick={handleChooseCancel}
                        sx={{
                            marginLeft: {
                                xs: "0",
                                md: "16px",
                            },
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "19.98px",
                            letterSpacing: "0.01em",
                        }}
                    >
                        YES, CANCEL
                    </Button>
                </Box>
            </Box>

            <MembershipCanceledModal ref={membershipCancelSuccessRef} />
        </Box>
    );
};

export default ReviewAndCancel;
