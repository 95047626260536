import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import ToggleGroupButton from "../ToggleGroupButton";
import { MenuItem } from "@mui/material";
import Select from 'Components/FormElements/Select'
import style from "./NumberAndHeightUnit.module.scss";
import { convertInchToFeet } from "utils/convertInchToFeet";

const convertFeetToInch = (feet: number) => {
    return feet * 12
}

const initInchList = [
    {value: 0, name: '0 in'},
    {value: 1, name: '1 in'},
    {value: 2, name: '2 in'},
    {value: 3, name: '3 in'},
    {value: 4, name: '4 in'},
    {value: 5, name: '5 in'},
    {value: 6, name: '6 in'},
    {value: 7, name: '7 in'},
    {value: 8, name: '8 in'},
    {value: 9, name: '9 in'},
    {value: 10, name: '10 in'},
    {value: 11, name: '11 in'},
]

const NumberAndHeightUnit = ({
    label,
    fontSize,
    unitData,
    error,
    handleChangeInch,
    height,
    unitFieldValue,
    unitFieldOnChange,
    unitFieldName,
    formik,
}: any) => {
    // Remove up/down arrow in number text field
    const useStyles: any = makeStyles({
        input: {
            "& input[type=number]": {
                "-moz-appearance": "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
            },
            "& input[type=number]::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
            },
        },
        button: {
            minWidth: 20,
            minHeight: 20,
            border: "none",
            textAlign: "center",
            margin: 5,
            padding: 0,
            background: "black",
            borderRadius: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        buttonText: {
            color: "white",
        },
    });
    const [feet, setFeet] = useState<any>(0);
    const [inch, setInch] = useState<any>(0);
    const [feetOptions, setFeetOptions] = useState([
        {value: 4, name: '4 ft'},
        {value: 5, name: '5 ft'},
        {value: 6, name: '6 ft'},
        {value: 7, name: '7 ft'},
        {value: 8, name: '8 ft'},
        {value: 9, name: '9 ft'},
    ]);
    const [inchOptions, setInchOptions] = useState(initInchList)

    const handleChangeChangeFeet = (event: any) => {
        const value = Number(event.target.value || 0);
        if(value>=9) {
            setInchOptions([{value: 0, name: '0 in'},])
            formik.setFieldValue('height', 9 * 12);
            return
        } else if (value===undefined) {
            handleChangeInch && handleChangeInch(0);
            return
        } else {
            setInchOptions(initInchList)
        }
        setFeet(value)
        const res = convertFeetToInch(value) + Number(inch)
        handleChangeInch && handleChangeInch(res)
    }

    const handleChangeChangeInch = (event: any) => {
        const value = Number(event.target.value || 0);
        if (value===undefined) {
            handleChangeInch && handleChangeInch(0);
            return
        }
        setInch(value)
        const res = convertFeetToInch(feet) + value
        handleChangeInch && handleChangeInch(res)
    }

    useEffect(()=>{
        if(height) {
            if(height >= 9*12) {
                formik.setFieldValue('height', 9 * 12);
                setFeet(9)
                setInch(0)
                setInchOptions([{value: 0, name: '0 in'}])
            } else if(height <= 4*12) {
                formik.setFieldValue('height',4 * 12);
                setFeet(4)
                setInch(0)
            }
            else {
                const res = convertInchToFeet(height);
                setFeet(res.feet)
                setInch(res.inch)
            }
        }
    }, [height])

    return (
        <div className={style["NumberAndHeightUnit"]}>
            <Grid container alignItems="center">
                <Grid item xs={12} sm={12} md={12} mb="8px">
                    <Box flexShrink={0} mr="30px">
                        <Typography component="label" variant="h6">
                            Height
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                    <Grid
                        container
                        spacing={{
                            xs: 2,
                            sm: 2,
                            md: 4,
                        }}
                    >
                        <Grid
                            item
                            xs={7}
                            sm={7}
                            md={5}
                            alignItems="center"
                            pl="14px"
                        >
                            <Grid container spacing={{xs: 1, sm: 1, md: 2}}>
                                <Grid item xs={6} alignItems="center" pl="14px" className={style['selectWrapper']}>
                                    <Select
                                        name={'feet'}
                                        value={feet}
                                        onChange={handleChangeChangeFeet}
                                        inputProps={{
                                            style: {
                                                height: "50px",
                                                padding: "0 14px",
                                                fontSize,
                                            },
                                        }}
                                        fullWidth
                                        options={feetOptions}
                                        placeholder={feet === 0 ? "0 ft" : ""}
                                    />
                                </Grid>
                                <Grid item xs={6} alignItems="center" pl="14px" className={style['selectWrapper']}>
                                    <Select
                                        name={'inch'}
                                        value={inch}
                                        onChange={handleChangeChangeInch}
                                        inputProps={{
                                            style: {
                                                height: "50px",
                                                padding: "0 14px",
                                                fontSize,
                                            },
                                        }}
                                        fullWidth
                                        options={inchOptions}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={5} sm={5} md={5}>
                            <FormControl
                                fullWidth
                                size="small"
                                variant="outlined"
                            >
                                <ToggleGroupButton
                                    options={unitData}
                                    defaultActive={unitFieldValue}
                                    changeToggle={unitFieldOnChange}
                                    name={unitFieldName}
                                />
                            </FormControl>
                        </Grid>
                        {error && (
                            <Box className="unitErrorText" color="red" fontSize={12}>
                                *{error}
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default NumberAndHeightUnit;
